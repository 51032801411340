import type { AllPageFilters } from "./AllPageFilters";
import { AllPageSearchParamKey } from "./AllPageSearchParamKey";
import { buildRegionParamValue } from "./buildRegionParamValue";
import { CommonSearchParamKey } from "./CommonSearchParamKey";

/**
 * TODO: 중고거래 필터 관련 로직 `/services/buy-sell/` 하위로 위치해야함
 */
export function buildAllPageParams(
  args: Parameters<typeof buildRegionParamValue>[0] & AllPageFilters,
) {
  const searchParams = new URLSearchParams();

  searchParams.set(
    CommonSearchParamKey.In,
    buildRegionParamValue({
      region: args.region,
    }),
  );

  if (args.categoryId) {
    searchParams.set(AllPageSearchParamKey.CategoryId, args.categoryId);
  }
  if (args.sortBy) {
    searchParams.set(AllPageSearchParamKey.SortBy, args.sortBy);
  }
  if (args.price) {
    searchParams.set(AllPageSearchParamKey.Price, args.price);
  }
  if (args.search) {
    searchParams.set(AllPageSearchParamKey.Search, args.search);
  }

  return searchParams;
}
